import { Customer } from "../generated/graphql";

type ICustomerNameSelector = (customer: Customer) => string;

type ICustomerAddressSelector = (customer: Customer) => string;

export const selectCustomerName: ICustomerNameSelector = (customer) =>
  customer.name || "";

export const selectCustomerAddress: ICustomerAddressSelector = (customer) =>
  customer.address || "";
