import { responsiveFontSizes } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import { blue } from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HookProvider } from "react-apollo-hooks";
import "./App.css";
import AppRouter from "./components/app-router";
import routeConfig from "./route-config";
import { GlobalStateProvider } from "./state";

const allowedOuterRoutes = ["elfelejtett-jelszo", "login", "lost-password"];

const isAllowedRoute = (routes: string[], href: string) => {
  let allowed = false;

  routes.forEach((route) => {
    if (!allowed) {
      allowed = href.indexOf(route) !== -1;
    }
  });

  return allowed;
};

if (
  !window.localStorage.getItem("auth_token") &&
  !isAllowedRoute(allowedOuterRoutes, window.location.href)
) {
  window.location.href = "/login";
}

const client = new ApolloClient({
  uri: "https://sales.hun-aqua.hu/api",
  request: (operation) => {
    const token = localStorage.getItem("auth_token");
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  cache: new InMemoryCache(),
  onError: (error) => {
    if (
      error.response &&
      error.response.errors &&
      error.response.errors[0] &&
      error.response.errors[0].message
    ) {
      switch (error.response.errors[0].message) {
        case "Not authenticated":
          window.location.href = "/login";
      }
    }
  },
});

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  props: {
    MuiPaper: {
      square: true,
    },
    MuiTextField: {
      fullWidth: true,
      variant: "outlined",
      margin: "none",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: "0 0 6px 1px rgba(0,0,0,0.2) !important",
      },
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: "bold",
        lineHeight: 1.2,
      },
      h5: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
        fontSize: "28px",
        paddingTop: "6px",
      },
      subtitle2: {
        fontWeight: "lighter",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "8.5px 14px",
      },
    },
    MuiInputLabel: {
      formControl: {
        top: -10,
      },
    },
  },
});

const responsiveFontTheme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg"],
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <HookProvider client={client}>
        <ThemeProvider theme={responsiveFontTheme}>
          <GlobalStateProvider>
            <Box className="App" bgcolor={"#fafafa"}>
              <AppRouter config={routeConfig} defaultRoute={"/login"} />
            </Box>
          </GlobalStateProvider>
        </ThemeProvider>
      </HookProvider>
    </ApolloProvider>
  );
};

export default App;
