import { Product, ProductUnit } from "../generated/graphql";
import { ICartProduct, ICartProductUnit } from "../reducer/cart";
import { formatCurrency } from "../utils/currency-formatter";
import { selectItemTotalPrice, selectItemTotalQuantity } from "./cart";

export const selectProductPrice = (product: Product) => product.price || 0;

export const selectProductPricePerUnitText = (product: Product): string => {
  const unit = selectProductUnit(product);

  if (product.price) {
    return `${formatCurrency(selectProductPrice(product))} ${
      unit ? ` / ${unit}` : ""
    }`;
  }

  return "";
};

export const selectProductSumText = (product: ICartProduct): string => {
  const unit = selectProductUnit(product.product);
  const qnt = selectItemTotalQuantity(product);

  if (unit) {
    return `${qnt} * ${formatCurrency(
      selectProductPrice(product.product),
    )} ( / ${unit}) = ${formatCurrency(selectItemTotalPrice(product))}`;
  }

  return `${qnt} * ${formatCurrency(
    selectProductPrice(product.product),
  )}  = ${formatCurrency(selectItemTotalPrice(product))}`;
};

export const selectProductUnit = (
  product: Product,
): string | null | undefined => {
  if (product.units) {
    return selectUnitByUnits(product.units);
  }

  return null;
};

export const selectPieceUnitByUnits = (units: ProductUnit[]): ProductUnit =>
  units.find((unit) => unit.multiplier === 1) || units[0];

export const selectUnitByUnits = (
  units: ProductUnit[],
): string | null | undefined => {
  const oneMultiplierUnit = units.find((unit) => unit.multiplier === 1);

  if (oneMultiplierUnit) {
    return oneMultiplierUnit.unit;
  }

  return null;
};

export const selectSumQuantityFromUnits = (units: ICartProductUnit[]) => {
  return units.reduce(
    (sum, cartUnit) =>
      sum + (cartUnit.unit.multiplier || 0) * cartUnit.quantity,
    0,
  );
};

export const selectSumPriceFromUnitsAndProduct = (
  units: ICartProductUnit[],
  product: Product,
) => {
  const quantity = selectSumQuantityFromUnits(units);
  return quantity * selectProductPrice(product);
};

export const selectFormattedSumPriceFromUnitsAndProduct = (
  units: ICartProductUnit[],
  product: Product,
) => {
  const sum = selectSumPriceFromUnitsAndProduct(units, product);

  return formatCurrency(sum);
};
