import gql from "graphql-tag";

export const FIND_PRODUCT = gql`
  query products($name: String!, $cursor: Int) {
    products(where: { name_contains: $name } page: $cursor perPage: 100) {
      id
      name
      stock
      price
      units {
        unit
        multiplier
      }
      isHeavy
    }
  }
`;
