import Box, { BoxProps } from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import React from "react";

interface IBoxWithTitleProps {
  children?: any;
  title: string | number;
  innerBoxProps?: BoxProps;
  fullWidth?: boolean;
  disableDivider?: boolean;
  TypographyProps?: TypographyProps;
}

const BoxWithTitle: React.FC<IBoxWithTitleProps> = (props) => {
  return (
    <Paper>
      <Box
        pt={2}
        pb={2}
        pl={props.fullWidth ? 0 : 2}
        pr={props.fullWidth ? 0 : 2}
        mb={2}
      >
        <Typography
          variant="h5"
          style={{ marginBottom: "1rem" }}
          {...props.TypographyProps}
        >
          {props.title}
        </Typography>
        {!props.disableDivider && <Divider />}
        <Box {...props.innerBoxProps}>{props.children}</Box>
      </Box>
    </Paper>
  );
};

export default BoxWithTitle;
