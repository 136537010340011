import gql from "graphql-tag";

interface ICustomer {
  id: string;
  name: string;
  address: string;
}

interface ICustomerSearchResult {
  customers: ICustomer[];
}

const SEARCH_CUSTOMERS_QUERY = gql`

  query ($name: String!, $cursor: Int){
    customers( where : {name_contains: $name} page: $cursor perPage: 20 )  {
      id
      name
      address
    }
  }

`;

interface ICustomerResult {
  customer: ICustomer;
}

// const GET_CUSTOMER = gql`
//   query customer($id: ID) {
//     customer(where: { id: $id }) {
//       id
//       name
//       address
//     }
//   }
// `;

export { SEARCH_CUSTOMERS_QUERY };
