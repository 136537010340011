import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import qs from "qs";
import React from "react";
import { useMutation } from "react-apollo-hooks";
import { match, Redirect } from "react-router-dom";
import Layout from "../../components/layout";
import PASSWORD_RESET_MUTATION from "../../mutations/passrowd-reset";
import SuccessPasswordChangeDialog from "./success-password-change-dialog";

interface IPasswordResetPageProps {
  match: match<{ token: string }>;
}

const PasswordResetPage: React.FC<IPasswordResetPageProps> = () => {
  const [password, setPassword] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [resetPassword, { data, loading }] = useMutation(
    PASSWORD_RESET_MUTATION,
  );
  const queryData: { email: string; token: string } = qs.parse(
    window.location.search.replace("?", ""),
  );
  const validQueryParams = Boolean(
    queryData.email.length && queryData.token.length,
  );

  const [navigateTo, setNavigaetTo] = React.useState<string | null>(
    validQueryParams ? null : "/login",
  );
  const [email, setEmail] = React.useState<string>(queryData.email);

  if (navigateTo) {
    return <Redirect to={navigateTo} />;
  }

  const emailValid = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    email,
  );
  let isValid = false;
  const hasPassLength = password.length > 1 && password2.length > 1;

  if (hasPassLength) {
    isValid = password2 === password;
  }

  return (
    <Layout disabledBottomAppbar>
      <Box>
        <Typography variant={"h5"}>Add meg a jelszavad!</Typography>
        <Typography variant={"body1"}>
          Ezzel a jelszóval tudsz majd belépni a rendszerbe.
        </Typography>
      </Box>

      <Box mt={4}>
        <Grid container direction={"column"} spacing={3}>
          <Grid item xs={12}>
            <TextField
              type={"text"}
              label={"E-mail cím"}
              error={!emailValid}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={showPassword ? "text" : "password"}
              label={"Jelszó"}
              error={!isValid}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type={showPassword ? "text" : "password"}
              label={"Jelszó újra"}
              error={!isValid}
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!isValid && (
              <FormHelperText error>A két jelszó nem ugyan az!</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={!isValid || !emailValid}
              onClick={() =>
                resetPassword({
                  variables: { email, token: queryData.token, password },
                })
              }
            >
              Jelszó megadása
            </Button>
          </Grid>
        </Grid>
      </Box>
      <SuccessPasswordChangeDialog
        open={!loading && data}
        onClose={() => setNavigaetTo("/login")}
      />
    </Layout>
  );
};

export default PasswordResetPage;
