import { Button, Divider, FormHelperText } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Paper from "@material-ui/core/Paper/Paper";
import TextField from "@material-ui/core/TextField/TextField";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { useApolloClient, useQuery } from "react-apollo-hooks";
import { match as IMatch } from "react-router";
import useRouter from "use-react-router";
import Layout from "../../components/layout";
import ProductList from "../../components/product-list";
import { ProductListItemMode } from "../../components/product-list-item";
import { Product } from "../../generated/graphql";
import POPULAR_PRODUCTS from "../../queries/popular-products";
import { FIND_PRODUCT } from "../../queries/products";
import { selectProductPrice } from "../../selectors/product";
import { useGlobalState } from "../../state";

interface IItemsPageProps {
  match: IMatch<{ customerId: string }>;
}

export type IAddToCart = (product: Product, quantity: number) => void;

const ItemsPage: React.FC<IItemsPageProps> = () => {
  const [cart] = useGlobalState("cart");
  const [searchText, setSearchText] = React.useState<string>("");
  const [popularProducts, setPopularProducts] = React.useState<Product[]>([]);
  const { loading, data } = useQuery(FIND_PRODUCT, {
    variables: { name: searchText },
  });
  const client = useApolloClient();

  React.useEffect(
    () => {
      if (cart.customer && cart.customer.id) {
        client
          .query({
            query: POPULAR_PRODUCTS,
            variables: {
              customer: cart.customer.id,
            },
          })
          .then((res) => setPopularProducts(res.data.popularProducts));
      }
    },
    [cart, client],
  );

  const { history } = useRouter();
  const debouncedSearchHandler = setSearchText;

  const isPopularProducts = Boolean(
    !searchText.length && popularProducts.length,
  );

  const productList = isPopularProducts
    ? popularProducts
    : data && data.products
      ? data.products
      : [];
  return (
    <Layout
      disablePadding
      rightSideComponents={
        <Button onClick={() => history.push("/cart")}>
          Kosár<ChevronRightIcon />
        </Button>
      }
      leftSideComponents={
        <Button onClick={() => history.push("/")}>
          <ChevronLeftIcon />Bolt
        </Button>
      }
    >
      <Paper>
        <Box p={2} pt={4}>
          <TextField
            label={"Termék keresése"}
            placeholder={"Csio..."}
            value={searchText}
            onChange={(event) => debouncedSearchHandler(event.target.value)}
            autoFocus
          />
        </Box>
      </Paper>
      <Box overflow={"scroll"}>
        {!loading && (
          <>
            {isPopularProducts && (
              <>
                <Box p={"4px 16px 10px"}>
                  <FormHelperText>Előzmények</FormHelperText>
                </Box>

                <Divider />
              </>
            )}
            <ProductList
              items={productList
                .map(
                  (p: Product): Product => ({
                    ...p,
                    price: selectProductPrice(p),
                  }),
                )
                .map((p: Product) => {
                  return {
                    product: p,
                    mode: ProductListItemMode.DISPLAY,
                  };
                })}
              getCartProduct={() => undefined}
            />
          </>
        )}
      </Box>
    </Layout>
  );
};

export default ItemsPage;
