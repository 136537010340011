import gql from "graphql-tag";

const ORDER_QUERY = gql`
  query OrderQuery($id: String!) {
    order(id: $id) {
      customer {
        id
        name
        address
      }
      items {
        product {
          id
          name
          stock
          price
          units {
            unit
            multiplier
          }
          isHeavy
        }
        quantity
      }
    }
  }
`;

export default ORDER_QUERY;
