import { IRouteConfig } from "./components/app-router";
import CartPage from "./pages/cart-page";
import HistoryDetailsPage from "./pages/history-details-page";
import HistoryPage from "./pages/history-page";
import HomePage from "./pages/home-page";
import ItemsPage from "./pages/items-page";
import LoginPage from "./pages/login-page";
import LostPasswordPage from "./pages/lost-password-page";
import PasswordResetPage from "./pages/password-reset-page";

const routeConfig: IRouteConfig[] = [
  {
    component: HistoryDetailsPage,
    exact: true,
    path: "/history/:id",
  },
  {
    component: HistoryPage,
    exact: true,
    path: "/history",
  },
  {
    component: LostPasswordPage,
    exact: true,
    path: "/lost-password",
  },
  {
    component: PasswordResetPage,
    exact: true,
    path: "/elfelejtett-jelszo",
  },
  {
    component: CartPage,
    exact: true,
    path: "/cart",
  },
  {
    component: LoginPage,
    exact: true,
    path: "/login",
  },
  {
    component: ItemsPage,
    exact: true,
    path: "/items",
  },
  {
    component: HomePage,
    exact: true,
    path: "/",
  },
];

export default routeConfig;
