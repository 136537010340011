import { Box, Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useMutation } from "react-apollo-hooks";
import { Redirect } from "react-router-dom";
import Layout from "../../components/layout";
import { LOST_PASSWORD } from "../../mutations/lost-password";
import SuccessResetDialog from "./success-reset-dialog";

const validateEmail = (email: string) =>
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    email,
  );

const LostPasswordPage = () => {
  const [email, setEmail] = React.useState<string>("");
  const [
    lostPasswordEmail,
    { loading, data, called, hasError },
  ] = useMutation(LOST_PASSWORD);
  const [navigateTo, setNavigaetTo] = React.useState<string | null>(null);

  if (navigateTo) {
    return <Redirect to={navigateTo} />;
  }

  return (
    <Layout disabledBottomAppbar>
      <Box mb={4}>
        <Typography variant={"h5"}>Elfelejtetted a jelszavad?</Typography>
      </Box>

      <Box mb={4}>
        <Typography variant={"body1"}>
          Add meg az email címed és elküldjük hogyan tudsz új jelszót csinálni.
        </Typography>
      </Box>

      <Box>
        <TextField
          onChange={(e) => {
            return setEmail(e.target.value);
          }}
          value={email}
          label={"E-mail cím"}
          required
        />
        <Button
          style={{ marginTop: "4px" }}
          variant={"contained"}
          color={"primary"}
          disabled={!validateEmail(email)}
          onClick={() => {
            if (validateEmail(email)) {
              lostPasswordEmail({ variables: { email } });
            }
          }}
        >
          E-mail küldése
        </Button>
      </Box>

      {hasError &&
        !loading && (
          <Box>
            <Typography variant={"body1"} color={"secondary"}>
              Hiba történt, próbálja újra
            </Typography>
          </Box>
        )}

      <SuccessResetDialog
        open={Boolean(data && called)}
        onClose={() => setNavigaetTo("/login")}
      />
    </Layout>
  );
};

export default LostPasswordPage;
