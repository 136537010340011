import { Typography } from "@material-ui/core";
import React from "react";
import YesNoDialog from "../yes-no-dialog/intex";

interface IConfirmRemoveDialogProps {
  open: boolean;
  onClose: (e: any) => void;
  onConfirm: (e: any) => void;
}

const ConfirmRemoveDialog: React.FC<IConfirmRemoveDialogProps> = (props) => {
  return (
    <YesNoDialog
      open={props.open}
      title={"Biztosan ki törlöd a terméket?"}
      yesText={"Igen"}
      noText={"Nem"}
      onYesClick={props.onConfirm}
      onNoClick={props.onClose}
      onClose={props.onClose}
    >
      <Typography variant={"body1"}>
        Ha kitörlöd a terméket, a már beállított mennyiségek el fognak veszni.
      </Typography>
    </YesNoDialog>
  );
};

export default ConfirmRemoveDialog;
