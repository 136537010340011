import { Box, Button, IconButton, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HistoryIcon from "@material-ui/icons/History";
import SendIcon from "@material-ui/icons/Send";
import React from "react";
import { useMutation } from "react-apollo-hooks";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";
import BoxWithTitle from "../../components/box-with-title";
import Layout from "../../components/layout";
import LogoutButton from "../../components/logout-button";
import OrderConfirmDialog from "../../components/order-confirm-dialog";
import SuccessOrderDialog from "../../components/success-order-dialog";
import { CREATE_ORDER } from "../../mutations/order-mutation";
import {
  selectCartCount,
  selectCartItems,
  selectOrderVariablesFromCart,
  selectTotalPrice,
} from "../../selectors/cart";
import {
  selectCustomerAddress,
  selectCustomerName,
} from "../../selectors/customer";
import { dispatch, useGlobalState } from "../../state";
import { formatCurrency } from "../../utils/currency-formatter";
import CartItemsSummaryList from "./cart-items-summary-list";
import { SummaryBox } from "./summary-box";

type ILinkRenderer = (to: string) => (props: any) => any;

export const secondaryLinkRenderer: ILinkRenderer = (to) => (props) => (
  <Link {...props} to={to} style={{ display: "block" }} />
);

const CartPage = () => {
  const [cart] = useGlobalState("cart");
  const [successOrder, setSuccessOrder] = React.useState<boolean>(false);
  const [confirmDialogShow, setConfirmDialogShow] = React.useState<boolean>(
    false,
  );
  const { history } = useRouter();
  const [orderCart, { data }] = useMutation(CREATE_ORDER);
  const [orderOtherCart, { data: otherData }] = useMutation(CREATE_ORDER);

  const cartCount = selectCartCount(cart.products);
  const total = selectTotalPrice(cart.products);
  const cartItems = selectCartItems(cart.products, false);
  const otherCartItems = selectCartItems(cart.products, true);
  const hasOtherCart = Boolean(otherCartItems.length);

  React.useEffect(
    () => {
      if (hasOtherCart) {
        if (otherData && otherData.createOrder.status === "CREATED") {
          dispatch({ type: "RESET_STATE" });
          localStorage.removeItem("cartState");
          setSuccessOrder(true);
        }
      } else if (data && data.createOrder.status === "CREATED") {
        dispatch({ type: "RESET_STATE" });
        localStorage.removeItem("cartState");
        setSuccessOrder(true);
      }
    },
    [data, otherData, hasOtherCart],
  );

  const onOrderClick = () => {
    setConfirmDialogShow(true);
  };

  const createOrder = () => {
    orderCart({
      variables: {
        ...selectOrderVariablesFromCart(cart, false),
      },
    });

    if (hasOtherCart) {
      orderOtherCart({
        variables: {
          ...selectOrderVariablesFromCart(cart, true),
        },
      });
    }
  };

  return (
    <Layout
      disablePadding
      leftSideComponents={
        <Button onClick={() => history.push("/items")}>
          <ChevronLeftIcon />Termékek
        </Button>
      }
      rightSideComponents={
        <>
          <IconButton onClick={() => history.push("/history")}>
            <HistoryIcon />
          </IconButton>
          <LogoutButton />
        </>
      }
    >
      <SummaryBox
        cart={cart}
        cartCount={cartCount}
        price={total}
        onOrderClick={onOrderClick}
      />
      <CartItemsSummaryList
        items={cartItems}
        // onSelectProduct={setSelected}
        title={"Kosár"}
      />

      {hasOtherCart && (
        <CartItemsSummaryList
          items={otherCartItems}
          // onSelectProduct={setSelected}
          title={"Másik kosár"}
          hidePrice
        />
      )}

      <BoxWithTitle
        title={"Megjegyzés"}
        TypographyProps={{
          style: { fontSize: "14px", textAlign: "left", marginBottom: "8px" },
        }}
      >
        <TextField
          variant={"outlined"}
          multiline
          rows={4}
          value={cart.comment}
          onChange={(e) =>
            dispatch({
              type: "SET_COMMENT",
              payload: { comment: e.target.value },
            })
          }
        />
      </BoxWithTitle>

      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          disabled={!cartCount || !cart.customer}
          onClick={onOrderClick}
          fullWidth
          style={{
            height: "65px",
            borderRadius: 0,
          }}
        >
          Rendelés
          <SendIcon style={{ marginLeft: 16 }} />
        </Button>
      </Box>

      <SuccessOrderDialog open={successOrder} />
      <OrderConfirmDialog
        open={confirmDialogShow}
        onClose={() => setConfirmDialogShow(false)}
        onConfirm={() => createOrder()}
        customer={
          cart.customer
            ? `${selectCustomerName(cart.customer)}, ${selectCustomerAddress(
                cart.customer,
              )}`
            : ""
        }
        price={formatCurrency(selectTotalPrice(cart.products))}
      />
    </Layout>
  );
};

export default CartPage;
