import { createStore } from "react-hooks-global-state";
import { HunaquaActions, rootReducer } from "./reducer";
import { ICartState } from "./reducer/cart";

export interface IHunaquaInitialState {
  readonly cart: ICartState;
}

const initialState: IHunaquaInitialState = {
  cart: {
    customer: null,
    products: [],
    comment: "",
  },
};

let startState = initialState;

const savedCart = localStorage.getItem("cartState");

if (savedCart) {
  try {
    startState = { ...startState, cart: JSON.parse(savedCart) };
  } catch (e) {
    console.log("Error saved cart parse", e);
  }
}

export const { GlobalStateProvider, useGlobalState, dispatch } = createStore<
  IHunaquaInitialState,
  HunaquaActions
>((state, action) => {
  if (action.type === "RESET_STATE") {
    return initialState;
  }

  return rootReducer(state, action);
}, startState);
