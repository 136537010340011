import { CreateOrderInput, Product } from "../generated/graphql";
import { ICartProduct, ICartState } from "../reducer/cart";
import { selectProductPrice, selectSumQuantityFromUnits } from "./product";

type ICartTotalPriceSelector = (products: ICartProduct[]) => number;

type ICartCountSelector = (products: ICartProduct[]) => number;

type ICartItemTotalSelector = (item: ICartProduct) => number;

type ICartItemSelector = (
  products: ICartProduct[],
  product: Product,
) => ICartProduct | undefined;

export const selectTotalPrice: ICartTotalPriceSelector = (products) => {
  return products.reduce((acc, item) => {
    return acc + selectItemTotalPrice(item);
  }, 0);
};

export const selectCartCount: ICartCountSelector = (products) =>
  products.length;

export const selectCartItem: ICartItemSelector = (products, product) => {
  const result = products.find((item) => item.product.id === product.id);

  return result;
};

export const selectItemTotalPrice: ICartItemTotalSelector = (item) => {
  return selectItemTotalQuantity(item) * selectProductPrice(item.product);
};

export const selectItemTotalQuantity = (item: ICartProduct): number =>
  item.units.reduce(
    (unitSum, unit) => unitSum + unit.quantity * (unit.unit.multiplier || 0),
    0,
  );

export const selectOrderVariablesFromCart = (
  cart: ICartState,
  isHeavy: boolean = false,
): CreateOrderInput => {
  const data: CreateOrderInput = {
    customerId: "",
    items: [],
  };

  if (cart.customer) {
    data.customerId = cart.customer.id || "";
    data.items = selectCartItems(cart.products, isHeavy).map((cartProduct) => ({
      id: cartProduct.product.id,
      quantity: selectSumQuantityFromUnits(cartProduct.units),
    }));
    data.isHeavy = isHeavy;
    data.note = cart.comment;
  }

  return data;
};

export const selectCartItems = (
  products: ICartProduct[],
  isHeavy: boolean,
): ICartProduct[] =>
  products.filter((item) => Boolean(item.isHeavy) === isHeavy);
