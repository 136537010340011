import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import DoneIcon from "@material-ui/icons/DoneOutlined";
import React from "react";
import { Link } from "react-router-dom";

// tslint:disable-next-line:only-arrow-functions
const Transition = React.forwardRef<unknown, TransitionProps>(function(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ISuccessOrderDialogProps {
  open: boolean;
}

const SuccessOrderDialog: React.FC<ISuccessOrderDialogProps> = (props) => {
  return (
    <Dialog open={props.open} fullScreen TransitionComponent={Transition}>
      <DialogTitle>
        <Typography variant={"body1"} align="center">
          Sikeres beküldés!
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DoneIcon style={{ width: 250, height: 250, color: "#90ee90" }} />
        <Typography variant={"h6"}>
          A rendelés véglegesítéséhez, számla generálásához meg kell erősíteni
          az indigo-ban a rendelést.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={"primary"}
          style={{
            width: "100%",
          }}
          component={(buttonProps: any) => <Link {...buttonProps} />}
          to={"/"}
        >
          Vissza a kezdőlapra
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessOrderDialog;
