import { Box, Typography } from "@material-ui/core";
import { History } from "history";
import React, { useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { Redirect, withRouter } from "react-router-dom";
import Layout from "../../components/layout";
import LoginForm from "../../components/login-form";
import { LOGIN_MUTATION } from "../../mutations/login";

interface ILoginPageProps {
  history: History;
}

const LoginPage: React.FC<ILoginPageProps> = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [login, { data, loading }] = useMutation(LOGIN_MUTATION);

  if (data && data.login && data.login.token) {
    localStorage.setItem("auth_token", data.login.token);
  }

  return data && data.login && data.login.token ? (
    <Redirect to={"/"} />
  ) : (
    <Layout disabledBottomAppbar>
      <Box mb={6} mt={6}>
        <Typography variant="h5">Bejelentkezés</Typography>
      </Box>
      <LoginForm
        onSubmit={(email, password) => {
          // props.history.push("/");
          setErrorMessage("");
          login({ variables: { email, password } }).catch((err) =>
            setErrorMessage(err.message),
          );
        }}
        loading={loading}
      />
      {errorMessage && (
        <Box color="red" position="fixed" bottom="2rem">
          {errorMessage}
        </Box>
      )}
    </Layout>
  );
};

export default withRouter(LoginPage);
