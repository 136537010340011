import gql from "graphql-tag";

const POPULAR_PRODUCTS = gql`
  query PopularProducts($customer: ID!) {
    popularProducts(customer: $customer) {
      id
      name
      stock
      price
      units {
        unit
        multiplier
      }
      isHeavy
    }
  }
`;

export default POPULAR_PRODUCTS;
