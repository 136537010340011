import { Grid, List } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import React from "react";
import BoxWithTitle from "../../components/box-with-title";
import { formatCurrency } from "../../utils/currency-formatter";
import { secondaryLinkRenderer } from "./index";

export function SummaryBox(props: {
  cart: any;
  cartCount: number;
  price: number;
  onOrderClick: (e: any) => void;
}) {
  return (
    <BoxWithTitle title={"Összegzés"}>
      <List>
        <ListItem disableGutters>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <ListItemText
                primary={"Kereskedés"}
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
                secondary={
                  props.cart.customer ? props.cart.customer.name : "Kiválaszt"
                }
                secondaryTypographyProps={{
                  variant: "body1",
                  component: secondaryLinkRenderer("/"),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary={"Termékek száma"}
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
                secondary={
                  props.cartCount ? `${props.cartCount} db` : "Hozzáad"
                }
                secondaryTypographyProps={{
                  variant: "body1",
                  component: !props.cartCount
                    ? secondaryLinkRenderer("/items")
                    : undefined,
                }}
              />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem disableGutters>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item xs={6}>
              <ListItemText
                primary={"Összesen"}
                primaryTypographyProps={{
                  variant: "subtitle1",
                }}
                secondary={
                  props.cartCount
                    ? `${formatCurrency(props.price)}`
                    : "A kosár üres"
                }
                secondaryTypographyProps={{
                  variant: "subtitle1",
                  style: { fontSize: "1.2rem" },
                  component: !props.cartCount
                    ? secondaryLinkRenderer("/items")
                    : undefined,
                  color: props.cartCount ? "error" : "textSecondary",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ListItemText>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  disabled={!props.cartCount || !props.cart.customer}
                  onClick={props.onOrderClick}
                >
                  Rendelés
                  <SendIcon style={{ marginLeft: 16 }} />
                </Button>
              </ListItemText>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </BoxWithTitle>
  );
}
