import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ProductIcon from "@material-ui/icons/BallotRounded";
import MarketIcon from "@material-ui/icons/HomeRounded";
import CartIcon from "@material-ui/icons/ShoppingCartRounded";
import React, { ChangeEvent } from "react";

interface IBottomAppBarProps {
  url: string;
  productsCount: number;
  onChange(e: ChangeEvent<{}>, newValue: string): void;
}

const BottomAppBar: React.FC<IBottomAppBarProps> = (props) => {
  return (
    <BottomNavigation
      style={{
        bottom: 0,
        left: 0,
        right: 0,
        position: "sticky",
        boxShadow: "0 0 6px 1px rgba(0,0,0,0.2)",
        zIndex: 10,
      }}
      value={props.url}
      onChange={props.onChange}
    >
      <BottomNavigationAction
        value={"/"}
        label={"Kereskedés"}
        icon={<MarketIcon />}
      />
      <BottomNavigationAction
        value={"/items"}
        label={"Termékek"}
        icon={<ProductIcon />}
      />
      <BottomNavigationAction
        value={"/cart"}
        label={"Kosár"}
        icon={
          <Badge
            badgeContent={props.productsCount}
            color={"primary"}
            showZero={false}
          >
            <CartIcon />
          </Badge>
        }
      />
    </BottomNavigation>
  );
};

export default BottomAppBar;
