import { Box, Grid } from "@material-ui/core";
import MUIAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React, { ReactElement } from "react";
import { withRouter } from "react-router-dom";
import HunaquaLogo from "./hunaqua-logo.png";

export interface IProps {
  leftSideComponents?: ReactElement | ReactElement[];
  rightSideComponents?: ReactElement | ReactElement[];
  // history?: History;
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  root: {
    marginBottom: 0,
    position: "sticky",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

const allowedOuterRoutes = ["login", "lost-password"];

const isAllowedRoute = (routes: string[], href: string) => {
  let allowed = false;

  routes.forEach((route) => {
    if (!allowed) {
      allowed = href.indexOf(route) !== -1;
    }
  });

  return allowed;
};

function AppBar({ leftSideComponents, rightSideComponents, history }: any) {
  const classes = useStyles();

  // todo ez itt egy gyors hak
  const imgProps: any = { style: { width: "110px" } };

  if (isAllowedRoute(allowedOuterRoutes, window.location.href)) {
    imgProps.onClick = () => history.push("/");
    imgProps.style.cursor = "pointer";
  }

  return (
    <div className={classes.root}>
      <MUIAppBar position={"relative"} color={"inherit"}>
        <Toolbar disableGutters>
          <Grid item xs={4}>
            <Box textAlign={"left"}>{leftSideComponents}</Box>
          </Grid>
          <Grid item xs={4} className={classes.title}>
            <img src={HunaquaLogo} alt={"hunaqua-logo"} {...imgProps} />
          </Grid>
          <Grid item xs={4}>
            <Box textAlign={"right"}>{rightSideComponents}</Box>
          </Grid>
        </Toolbar>
      </MUIAppBar>
    </div>
  );
}

export default withRouter(AppBar);
