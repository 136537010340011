import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";

const MarketMap = () => {
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 47.5183783, lng: 19.1643246 }}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    >
      <Marker position={{ lat: 47.5183783, lng: 19.1643246 }} />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(MarketMap));
