import gql from "graphql-tag";
import { Mutation } from "react-apollo";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        name
      }
    }
  }
`;

export interface ILoginResponse {
  login: {
    token: string;
  };
}

export interface ILoginVariables {
  username: string;
  password: string;
}

export class LoginMutation extends Mutation<ILoginResponse, ILoginVariables> {}

// export default LOGIN_MUTATION;
