import currenctFormatter from "currency-formatter";

type ICurrencyFormatter = (price: any) => string;

// const hufOptions = {
//   style: "currency",
//   currency: "HUF",
//   maximumSignificantDigits: 1,
// };
// const formatter = new Intl.NumberFormat("hu-HU", hufOptions);

export const formatCurrency: ICurrencyFormatter = (price) => {
  return currenctFormatter.format(price, { locale: "hu-HU", code: "HUF", precision: 0 });
};
