import gql from "graphql-tag";

const FAVORITES_QUERY = gql`
  query Favorites {
    favorites {
      name
      address
      id
    }
  }
`;

export default FAVORITES_QUERY;
