import gql from "graphql-tag";

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $customerId: String!
    $isHeavy: Boolean!
    $items: [CreateOrderItemInput!]!
    $note: String!
  ) {
    createOrder(
      data: { customerId: $customerId, isHeavy: $isHeavy, items: $items, note: $note }
    ) {
      id
      status
    }
  }
`;
