import { Customer, Product, ProductUnit } from "../generated/graphql";

export interface ICartProductUnit {
  readonly unit: ProductUnit;
  readonly quantity: number;
}

export interface ICartProduct {
  readonly product: Product;
  readonly units: ICartProductUnit[];
  readonly isHeavy?: boolean;
}

export interface ICartState {
  readonly customer: Customer | null;
  readonly products: ICartProduct[];
  readonly comment: string;
}

interface ISetCustomer {
  type: "SET_CUSTOMER";
  payload: {
    customer: Customer;
  };
}

interface IAddToCart {
  type: "ADD_TO_CART";
  payload: ICartProduct;
}

interface IRemoveProduct {
  type: "REMOVE_PRODUCT";
  payload: {
    id: string;
  };
}

interface ISetComment {
  type: "SET_COMMENT";
  payload: {
    comment: string;
  };
}

interface IResetState {
  type: "RESET_STATE";
}
export type CartActions =
  | IAddToCart
  | ISetCustomer
  | IResetState
  | IRemoveProduct
  | ISetComment;

type CartReducer = (state: ICartState, action: CartActions) => ICartState;

export const cartReducer: CartReducer = (state, action) => {
  const getNewState = () => {
    switch (action.type) {
      case "ADD_TO_CART":
        let products = [];
        const formattedProduct = {
          ...action.payload,
          product: {
            ...action.payload.product,
            price: action.payload.isHeavy ? 0 : action.payload.product.price,
          },
        };

        const inCartIndex = state.products.findIndex(
          (item) => item.product.id === action.payload.product.id,
        );

        if (inCartIndex !== -1) {
          products = state.products.map(
            (item, index) => (index === inCartIndex ? formattedProduct : item),
          );
        } else {
          products = [...state.products, formattedProduct];
        }

        return {
          ...state,
          products,
        };

      case "SET_CUSTOMER":
        return {
          ...state,
          customer: action.payload.customer,
        };

      case "REMOVE_PRODUCT":
        return {
          ...state,
          products: state.products.filter(
            (item) => item.product.id !== action.payload.id,
          ),
        };

      case "SET_COMMENT": {
        return {
          ...state,
          comment: action.payload.comment,
        };
      }

      default:
        throw new Error("Unknown action: " + JSON.stringify(action));
    }
  };

  const newState = getNewState();
  window.localStorage.setItem("cartState", JSON.stringify(newState));
  return newState;
};
