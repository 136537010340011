import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

export interface IRouteConfig {
  path: string;
  component: any;
  exact?: boolean;
}

interface IProps {
  config: IRouteConfig[];
  defaultRoute: string;
}

const AppRouter = (props: IProps) => (
  <BrowserRouter>
    <Switch>
      {props.config.map((item) => (
        <Route
          path={item.path}
          component={item.component}
          key={`AppRouter-Route-${item.path}`}
        />
      ))}
      <Redirect to={props.defaultRoute} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
