import gql from "graphql-tag";

const PASSWORD_RESET_MUTATION = gql`
  mutation PasswordReset($email: String!, $password: String!, $token: String!) {
    passwordReset(password: $password, email: $email, token: $token) {
      user {
        email
      }
    }
  }
`;

export default PASSWORD_RESET_MUTATION;
