import { FormHelperText } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface IProps {
  loading: boolean;
  onSubmit(username: string, password: string): void;
}

const LoginForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(username, password);
      }}
    >
      <Box mb={3}>
        <TextField
          name="username"
          label="Felhasználónév"
          required
          type="email"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
        />
      </Box>
      <Box mb={3}>
        <TextField
          name="password"
          label="Jelszó"
          required
          type="password"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
      </Box>
      <Button
        type="submit"
        disabled={loading}
        variant="outlined"
        color="primary"
        size="large"
      >
        Bejelentkezés
      </Button>
      <Box mt={1 }>
        <FormHelperText
          component={(props) => <Link className={props.className} to={"/lost-password"}>{props.children}</Link>}
        >
          Elfelejtett jelszó
        </FormHelperText>
      </Box>
    </form>
  );
};

export default LoginForm;
