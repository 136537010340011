import Box from "@material-ui/core/Box/Box";
import Paper from "@material-ui/core/Paper/Paper";
import React from "react";
import { useApolloClient } from "react-apollo-hooks";
import Autosuggest from "react-autosuggest";
import { Customer } from "../../generated/graphql";
import { SEARCH_CUSTOMERS_QUERY } from "../../queries/customers";
import renderMarketInputComponent from "./render-market-input-component";
import renderMarketSuggestion from "./render-market-suggestion";

interface IProps {
  onMarketSelected(selected: Customer): void;
}

const MarketAutosuggest: React.FC<IProps> = ({ onMarketSelected }) => {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [markets, setMarkets] = React.useState<Customer[]>([]);
  const client = useApolloClient();

  React.useEffect(
    () => {
      if (inputValue.length > 2) {
        client
          .query({
            query: SEARCH_CUSTOMERS_QUERY,
            variables: { name: inputValue },
          })
          .then((res) => {
            if (Array.isArray(res.data.customers)) {
              setMarkets(res.data.customers);
            }
          })
          .catch((err) => console.error(err));
      }
    },
    [inputValue, client],
  );

  return (
    <Paper style={{ paddingTop: "16px" }}>
      <Box padding={2}>
        <Autosuggest
          focusInputOnSuggestionClick={false}
          renderInputComponent={renderMarketInputComponent}
          renderSuggestion={renderMarketSuggestion}
          suggestions={markets || []}
          inputProps={{
            onChange: (e, { newValue }) => {
              setInputValue(newValue);
            },
            value: inputValue,
          }}
          onSuggestionsFetchRequested={({ value }) => setInputValue(value)}
          onSuggestionSelected={(e, data) => {
            e.preventDefault();
            onMarketSelected(data.suggestion);
          }}
          onSuggestionsClearRequested={() => {
            // onSuggestionRequested("");
          }}
          getSuggestionValue={(item) => {
            return (item || {}).name || "";
          }}
          renderSuggestionsContainer={(options) => (
            <Box position="absolute" width="100%">
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            </Box>
          )}
        />
      </Box>
    </Paper>
  );
};

export default MarketAutosuggest;
