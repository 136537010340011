import { Box, Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { match } from "react-router";
import useRouter from "use-react-router";
import BoxWithTitle from "../../components/box-with-title";
import Layout from "../../components/layout";
import ProductList from "../../components/product-list";
import { ProductListItemMode } from "../../components/product-list-item";
import { Order, OrderItem } from "../../generated/graphql";
import ORDER_QUERY from "../../queries/order";
import { selectPieceUnitByUnits } from "../../selectors/product";
import { dispatch, useGlobalState } from "../../state";
import ConfirmResetStateDialog from "./confirm-reset-state-dialog";

interface IProps {
  match: match<{ id: string }>;
}

const HistoryDetailsPage: React.FC<IProps> = (props) => {
  const { data } = useQuery<{ order: Order }>(ORDER_QUERY, {
    variables: { id: props.match.params.id },
  });
  const [confirmDialogShow, setConfirmDialogShow] = React.useState<boolean>(
    false,
  );
  const [cart] = useGlobalState("cart");
  const { history } = useRouter();

  const hasCustomerOrProducts = Boolean(cart.customer || cart.products.length);
  const setCartState = () => {
    if (data) {
      dispatch({ type: "RESET_STATE" });
      dispatch({
        type: "SET_CUSTOMER",
        payload: { customer: data.order.customer },
      });

      if (data.order.items) {
        data.order.items.forEach((item) => {
          if (item && item.quantity) {
            const pieceUnit = selectPieceUnitByUnits(item.product.units);

            dispatch({
              type: "ADD_TO_CART",
              payload: {
                product: item.product,
                units: [{ unit: pieceUnit, quantity: item.quantity }],
              },
            });
          }
        });
      }

      history.push("/cart");
    }
  };

  return (
    <Layout
      disablePadding
      leftSideComponents={
        <Button onClick={() => history.push("/history")}>
          <ChevronLeftIcon /> Vissza
        </Button>
      }
    >
      <BoxWithTitle title={"Rendelés előzmény"} fullWidth>
        {data &&
          data.order && (
            <div style={{ paddingTop: "9px" }}>
              <Typography variant={"subtitle1"}>
                {data.order.customer.name}
              </Typography>
              <Typography variant={"body2"}>
                {data.order.customer.address}
              </Typography>
            </div>
          )}
      </BoxWithTitle>

      {data &&
        data.order &&
        data.order.items && (
          <React.Fragment>
            <BoxWithTitle title={"Termékek"} fullWidth>
              <ProductList
                items={data.order.items
                  .filter((item) => item !== null)
                  // @ts-ignore
                  .map((p: OrderItem) => {
                    if (p) {
                      return {
                        ...p,
                        mode: ProductListItemMode.HISTORY,
                      };
                    }

                    return p;
                  })}
                getCartProduct={() => undefined}
                hidePrice={false}
              />
            </BoxWithTitle>
            <Box>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                style={{
                  position: "absolute",
                  bottom: 56,
                  left: 0,
                  right: 0,
                }}
                onClick={() => {
                  if (hasCustomerOrProducts) {
                    setConfirmDialogShow(true);
                  } else {
                    setCartState();
                  }
                }}
              >
                Újra rendelem
              </Button>
            </Box>
          </React.Fragment>
        )}
      <ConfirmResetStateDialog
        open={confirmDialogShow}
        handleClose={() => setConfirmDialogShow(false)}
        handleReorder={() => setCartState()}
      />
    </Layout>
  );
};

export default HistoryDetailsPage;
