import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { Product, ProductUnit } from "../../generated/graphql";
import { useGlobalState } from "../../state";
import QuantityInput from "../product-list-item/quantity-input";
import UnitCounter from "../unit-counter";

export interface IAddToCartProps {
  product?: Product | null;
  open: boolean;
  handleClose: (e: any) => void;
  handleAddToCart: (state: IInitialState, product: Product) => void;
}

interface IInitialState {
  readonly units: Array<{
    readonly unit: ProductUnit;
    readonly quantity: number;
  }>;
  readonly isHeavy?: boolean;
}

interface ISetQuantityAction {
  type: "SET_QUANTITY";
  payload: {
    unit: ProductUnit;
    quantity: number;
  };
}

interface IResetAction {
  type: "RESET";
}

interface ISetCartAction {
  type: "SET_CART";
  payload: IInitialState;
}

interface ISetHeavyAction {
  type: "SET_HEAVY";
  payload: {
    heavy: boolean;
  };
}

type Actions =
  | ISetQuantityAction
  | IResetAction
  | ISetHeavyAction
  | ISetCartAction;

// tslint:disable-next-line:only-arrow-functions
const Transition = React.forwardRef<unknown, TransitionProps>(function(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const initialState = { units: [] };

const cartItemReducer = (
  state: IInitialState,
  action: Actions,
): IInitialState => {
  switch (action.type) {
    case "SET_QUANTITY": {
      const existingItemIndex = state.units.findIndex((unit) => {
        return unit.unit.unit === action.payload.unit.unit;
      });

      if (existingItemIndex !== -1) {
        return {
          ...state,
          units: state.units.map((unit, index) => {
            if (existingItemIndex === index) {
              return { ...unit, quantity: action.payload.quantity };
            }

            return unit;
          }),
        };
      }

      return {
        ...state,
        units: [
          ...state.units,
          { unit: action.payload.unit, quantity: action.payload.quantity },
        ],
      };
    }

    case "RESET":
      return initialState;

    case "SET_CART":
      return { ...action.payload };

    case "SET_HEAVY":
      return { ...state, isHeavy: action.payload.heavy };
  }
};

const AddToCartDialog: React.FC<IAddToCartProps> = (props) => {
  const [state, dispatch] = React.useReducer(cartItemReducer, initialState);
  const [cart] = useGlobalState("cart");
  const cartProduct = cart.products.find((cartP) => {
    return cartP.product.id === (props.product && props.product.id);
  });

  const selectUnitValue = (unit: ProductUnit) => {
    const item = state.units.find((u) => u.unit.unit === unit.unit);

    if (item) {
      return item.quantity;
    }

    return 0;
  };

  React.useEffect(
    () => {
      const asd = cart.products.find(
        (cartP) => cartP.product.id === (props.product && props.product.id),
      );
      dispatch({ type: "RESET" });
      if (asd) {
        dispatch({
          type: "SET_CART",
          payload: {
            units: asd.units,
            isHeavy: asd.isHeavy,
          },
        });
      }
    },
    [props.open, cartProduct, cart.products, props.product],
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ padding: "16px 40px 16px 24px" }}>
        <Typography variant={"subtitle1"} align="center">
          {props.product && props.product.name}
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 0, top: 0 }}
          onClick={(e) => props.handleClose(e)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: "flex" }}>
        <Grid container alignItems={"flex-end"}>
          <Grid item xs={12} style={{ height: "80px" }}>
            {props.product && state.units && Boolean(state.units.length) ? (
              <UnitCounter units={state.units} product={props.product} />
            ) : (
              <Box margin={2}>
                <Typography variant={"h6"} align="center">
                  {props.product &&
                  props.product.units &&
                  props.product.units.length
                    ? "Add meg a mennyiséget"
                    : "Nincs felvéve mennysiégi egység"}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="column" spacing={3}>
                  {props.product &&
                    props.product.units &&
                    props.product.units.map((unit) => (
                      <React.Fragment key={`unit-${unit.unit}`}>
                        <Grid item>
                          <Grid
                            container
                            alignItems="center"
                            direction={"row-reverse"}
                          >
                            <Grid item xs={8}>
                              <QuantityInput
                                value={selectUnitValue(unit)}
                                onChange={(value) => {
                                  dispatch({
                                    type: "SET_QUANTITY",
                                    payload: { unit, quantity: value },
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" align="center">
                                {unit.unit}:
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />
                      </React.Fragment>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {props.product && (
        <DialogActions style={{ justifyContent: "stretch" }}>
          <Button
            color="secondary"
            variant="contained"
            style={{ flexBasis: "50%" }}
            onClick={(e) => props.handleClose(e)}
          >
            Mégse
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ flexBasis: "50%" }}
            disabled={!state.units.length}
            onClick={() =>
              props.handleAddToCart(state, props.product as Product)
            }
          >
            Hozzáad
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddToCartDialog;
