import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import React, { ComponentProps } from "react";
import { Product } from "../../generated/graphql";
import { ICartProduct } from "../../reducer/cart";
import { dispatch, useGlobalState } from "../../state";
import AddToCartDialog from "../add-to-cart-dialog";
import ConfirmRemoveDialog from "../confirm-remove-dialog";
import ProductListItem, {
  IProductListItemProps,
  ProductListItemMode,
} from "../product-list-item";

type IProductListProps = {
  items: Array<ICartProduct & { mode: ProductListItemMode, quantity?: number }>;
  listProps?: ComponentProps<typeof List>;
} & Omit<
  IProductListItemProps,
  "product" | "mode" | "quantity" | "onDeleteProduct" | "onSelectProduct"
>;

const ProductList: React.FC<IProductListProps> = (props) => {
  const [cart] = useGlobalState("cart");
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null,
  );
  const [productRemove, setProductRemove] = React.useState<Product | null>(
    null,
  );

  return (
    <>
      <List {...props.listProps}>
        {props.items.map((item) => {
          return (
            <React.Fragment key={`products-list-item-${item.product && item.product.name}`}>
              <ProductListItem
                key={`products-list-item-${item.product && item.product.name}`}
                product={item.product}
                mode={item.mode}
                onSelectProduct={setSelectedProduct}
                getCartProduct={(product) =>
                  cart.products.find(
                    (cartP) => cartP.product.id === (product && product.id),
                  )
                }
                onDeleteProduct={(product) => {
                  setProductRemove(product);
                }}
                hidePrice={props.hidePrice}
                quantity={item.quantity}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
      <ConfirmRemoveDialog
        open={Boolean(productRemove)}
        onClose={() => setProductRemove(null)}
        onConfirm={() =>
          dispatch({
            type: "REMOVE_PRODUCT",
            payload: { id: productRemove ? productRemove.id || "" : "" },
          })
        }
      />
      <AddToCartDialog
        product={selectedProduct}
        open={Boolean(selectedProduct)}
        handleClose={() => setSelectedProduct(null)}
        handleAddToCart={(state, product) => {
          dispatch({ type: "ADD_TO_CART", payload: { ...state, product } });
          setSelectedProduct(null);
        }}
      />
    </>
  );
};

export default ProductList;
