import { Typography } from "@material-ui/core";
import React from "react";
import YesNoDialog from "../../components/yes-no-dialog/intex";

interface IProps {
  open: boolean;
  handleClose: (e: any) => void;
  handleReorder: (e: any) => void;
}

const ConfirmResetStateDialog: React.FC<IProps> = (props) => {
  return (
    <YesNoDialog
      open={props.open}
      title={" Az újra rendeléssel a kosárba rakott termékek el fognak veszni!"}
      yesText={"Igen"}
      noText={"Mégse"}
      onYesClick={props.handleReorder}
      onNoClick={props.handleClose}
      onClose={props.handleClose}
    >
      <Typography variant={"body1"}>Biztosan újra rendeled?</Typography>
    </YesNoDialog>
  );
};

export default ConfirmResetStateDialog;
