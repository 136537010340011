import { Button, Fab, IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import HistoryIcon from "@material-ui/icons/History";
import { History } from "history";
import React from "react";
import { useMutation, useQuery } from "react-apollo-hooks";
import { withRouter } from "react-router-dom";
import useRouter from "use-react-router";
import Layout from "../../components/layout";
import LogoutButton from "../../components/logout-button";
import MarketAutosuggest from "../../components/market-autosuggest";
import MarketDetails from "../../components/market-details";
import YesNoDialog from "../../components/yes-no-dialog/intex";
import { Customer } from "../../generated/graphql";
import ADD_FAVORITE from "../../mutations/add-favorite";
import REMOVE_FAVORITE from "../../mutations/remove-favorite";
import FAVORITES_QUERY from "../../queries/favorite";
import { dispatch, useGlobalState } from "../../state";
import FavoriteList from "./favorite-list";

interface IHomePageProps {
  history: History;
}

type ISetCustomer = (customer: Customer) => void;

const setCustomer: ISetCustomer = (customer) =>
  dispatch({
    type: "SET_CUSTOMER",
    payload: { customer },
  });

const HomePage: React.FC<IHomePageProps> = () => {
  const [cart] = useGlobalState("cart");
  const [selectedMarket, setSelectedMarket] = React.useState<null | Customer>(
    cart.customer,
  );
  const [removeFavoriteShow, setRemoveFavoriteShow] = React.useState<boolean>(
    false,
  );

  const favoritesQuery = useQuery(FAVORITES_QUERY);
  const [addFavoriteMutation] = useMutation(ADD_FAVORITE);
  const [removeFavoriteMutation] = useMutation(REMOVE_FAVORITE);

  const favorites =
    favoritesQuery.loading || favoritesQuery.error
      ? []
      : favoritesQuery.data.favorites;

  const isMarketFavorite: boolean = Boolean(
    favorites.find(
      (favorite: Customer) =>
        favorite.id === (selectedMarket && selectedMarket.id),
    ),
  );

  const { history } = useRouter();

  const makeFavoriteHandler = (
    mutation: typeof addFavoriteMutation | typeof removeFavoriteMutation,
  ) => () => {
    if (selectedMarket) {
      mutation({
        variables: {
          customer: selectedMarket.id,
        },
      })
        .then(() => favoritesQuery.refetch())
        .catch(() => favoritesQuery.refetch());
    }
  };

  const addFavorite = makeFavoriteHandler(addFavoriteMutation);
  const removeFavorite = makeFavoriteHandler(removeFavoriteMutation);

  const onFavoriteClick = () => {
    if (isMarketFavorite) {
      setRemoveFavoriteShow(true);
    } else {
      addFavorite();
    }
  };

  return (
    <Layout
      disablePadding
      rightSideComponents={
        <Button onClick={() => history.push("/items")}>
          Termékek <ChevronRightIcon />
        </Button>
      }
      leftSideComponents={
        <>
          <IconButton onClick={() => history.push("/history")}>
            <HistoryIcon />
          </IconButton>
          <LogoutButton />
        </>
      }
    >
      <Box>
        <MarketAutosuggest
          onMarketSelected={(market) => {
            setSelectedMarket(market);
            setCustomer(market);
          }}
        />
      </Box>
      <Box position={"relative"}>
        {selectedMarket && (
          <>
            <MarketDetails
              market={selectedMarket}
              onClose={() => {
                setSelectedMarket(null);
              }}
            />
          </>
        )}
        {Boolean(favorites.length && !selectedMarket) && (
          <FavoriteList
            items={favorites}
            onCustomerClick={(customer) => {
              setSelectedMarket(customer);
              setCustomer(customer);
            }}
          />
        )}
      </Box>
      <YesNoDialog
        open={removeFavoriteShow}
        title={"Biztos eltávolítod a kedvencek közül?"}
        yesText={"Igen"}
        noText={"Mégse"}
        onYesClick={() => removeFavorite()}
        onNoClick={() => setRemoveFavoriteShow(false)}
        onClose={() => setRemoveFavoriteShow(false)}
      />
      {selectedMarket && (
        <Fab
          onClick={onFavoriteClick}
          color={"primary"}
          style={{
            position: "absolute",
            bottom: "4rem",
            right: "2rem",
          }}
        >
          {isMarketFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </Fab>
      )}
    </Layout>
  );
};

export default withRouter(HomePage);
