import gql from "graphql-tag";

const ME_QUERY = gql`
  query MeQuery {
    me {
      user {
        id
      }
      orders {
        id
        number
        customer {
          id
          name
          address
        }
        items {
          product {
            id
            name
            stock
            price
            units {
              unit
              multiplier
            }
            isHeavy
          }
        }
      }
    }
  }
`;

export default ME_QUERY;
