import {
  Box,
  Button,
  Chip,
  Divider,
  FormHelperText,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import ChevronLefttIcon from "@material-ui/icons/ChevronLeft";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import useRouter from "use-react-router";
import BoxWithTitle from "../../components/box-with-title";
import Layout from "../../components/layout";
import { AuthenticatedUserPayload } from "../../generated/graphql";
import ME_QUERY from "../../queries/me";

const HistoryPage = () => {
  const { data, loading } = useQuery<{ me: AuthenticatedUserPayload }>(
    ME_QUERY,
    {
      fetchPolicy: "no-cache",
    },
  );

  const { history } = useRouter();

  return (
    <Layout
      disablePadding
      leftSideComponents={
        <Button onClick={() => history.push("/cart")}>
          <ChevronLefttIcon /> Kosár
        </Button>
      }
    >
      <BoxWithTitle title={"Rendelés előzmények"} disableDivider>
        <FormHelperText>Utolsó 25 rendelés</FormHelperText>
        {data &&
          data.me &&
          data.me.orders &&
          !loading && (
            <List>
              <Divider />
              {data.me.orders.map((item) => {
                return (
                  <React.Fragment>
                    <ListItem
                      disableGutters
                      button
                      onClick={() =>
                        history.push(`/history/${item && item.id}`)
                      }
                    >
                      <Box>
                        <Typography variant={"subtitle1"}>
                          {item && item.customer && item.customer.name}
                        </Typography>
                        <Typography variant={"body2"}>
                          {item && item.items && item.items.length} termék
                        </Typography>
                        <Chip
                          label={
                            <Typography variant={"body2"}>
                              {item && item.status === "BILLED"
                                ? "Számlázva"
                                : "Beküldve"}
                            </Typography>
                          }
                          style={{
                            backgroundColor:
                              item && item.status === "BILLED"
                                ? "#48c774"
                                : "#00d1b2",
                            color: "white",
                          }}
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          )}
      </BoxWithTitle>
    </Layout>
  );
};

export default HistoryPage;
