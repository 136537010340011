import {Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import YesNoDialog from "../yes-no-dialog/intex";

const LogoutButton = () => {
  const [confirmPopupShow, setConfirmPopup] = React.useState<boolean>(false);

  return (
    <>
      <IconButton onClick={() => setConfirmPopup(true)}>
        <LogoutIcon />
      </IconButton>
      <YesNoDialog
        open={confirmPopupShow}
        title={"Kijelentkezés"}
        yesText={"Igen"}
        noText={"Nem"}
        onYesClick={() => {
          localStorage.removeItem("auth_token");
          window.location.href = "/login";
        }}
        onNoClick={() => setConfirmPopup(false)}
        onClose={() => setConfirmPopup(false)}
      >
        <Typography>Biztos ki szeretnél lépni?</Typography>
      </YesNoDialog>
    </>
  );
};

export default LogoutButton;
