import { Button, ListItem, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React from "react";
import { Product } from "../../generated/graphql";
import { ICartProduct } from "../../reducer/cart";
import { selectProductPricePerUnitText } from "../../selectors/product";
import UnitCounter from "../unit-counter";

export enum ProductListItemMode {
  DISPLAY = "DISPLAY",
  ADD = "ADD",
  CART = "CART",
  HISTORY = "HISTORY",
}

export interface IProductListItemProps {
  product: Product;
  getCartProduct: (product: Product) => ICartProduct | undefined;
  mode: ProductListItemMode;
  hidePrice?: boolean;
  quantity?: number;
  onSelectProduct(product: Product): void;
  onDeleteProduct(product: Product): void;
}

const ProductListItem: React.FC<IProductListItemProps> = (props) => {
  const cartProduct = props.getCartProduct(props.product);
  return (
    <ListItem
      key={`list-item-${props.product.name}`}
      onClick={() => {
        if (props.mode === ProductListItemMode.DISPLAY) {
          props.onSelectProduct(props.product);
        }
      }}
    >
      <div>
        {props.mode === ProductListItemMode.DISPLAY && (
          <>
            <Typography variant={"subtitle1"}>{props.product.name}</Typography>
            <Typography variant={"body2"}>{`Készleten ${
              props.product.stock
            }`}</Typography>
            {!props.hidePrice && (
              <Typography variant={"caption"}>
                {selectProductPricePerUnitText(props.product)}
              </Typography>
            )}
            {cartProduct && (
              <ShoppingCartIcon
                color="primary"
                style={{
                  position: "absolute",
                  bottom: ".5rem",
                  right: ".5rem",
                }}
              />
            )}
          </>
        )}
        {props.mode === ProductListItemMode.CART && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ListItemText
                  primary={props.product.name}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    style: {
                      fontSize: "18px",
                    },
                  }}
                  style={{ flexBasis: 0, flexGrow: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                {cartProduct && (
                  <Grid container>
                    <UnitCounter
                      units={cartProduct.units}
                      product={cartProduct.product}
                      hidePrice={props.hidePrice}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={1} style={{ margin: "6px 0" }}>
                <Grid item xs={6}>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                      props.onSelectProduct(props.product);
                    }}
                    fullWidth
                  >
                    <EditIcon fontSize={"small"} />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => {
                      props.onDeleteProduct(props.product);
                    }}
                    fullWidth
                  >
                    <DeleteIcon fontSize={"small"} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {props.mode === ProductListItemMode.HISTORY && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <ListItemText
                  primary={props.product.name}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                  }}
                  style={{ flexBasis: 0, flexGrow: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>{props.quantity}</b> darab
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </ListItem>
  );
};

export default ProductListItem;
