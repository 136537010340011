import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";

interface IProps {
  open: boolean;
  title: string;
  yesText: string;
  noText: string;
  onYesClick: (e: any) => void;
  onNoClick: (e: any) => void;
  onClose: (e: any) => void;
  suppressAutoCloseOnYes?: boolean;
}

const YesNoDialog: React.FC<IProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={"md"}
      PaperProps={{ style: { margin: 0, width: "calc(100% - 55px)" } }}
    >
      <DialogTitle disableTypography>
        <Typography variant={"subtitle1"} style={{ fontSize: "20px" }}>
          {props.title}
        </Typography>
      </DialogTitle>

      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={props.onNoClick}
              fullWidth
            >
              Nem
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={(e) => {
                props.onYesClick(e);

                if (!props.suppressAutoCloseOnYes) {
                  props.onClose(e);
                }
              }}
              fullWidth
            >
              Igen
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default YesNoDialog;
