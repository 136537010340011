import TextField from "@material-ui/core/TextField";
import React from "react";

function renderMarketInputComponent(inputProps: any) {
  const { ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      autoFocus
      // inputProps={{
      //   ref: ref,
      // }}
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment position={'end'}>
      //       <IconButton onClick={}>
      //         <CloseIcon></CloseIcon>
      //       </IconButton>
      //     </InputAdornment>
      //   )
      // }}
      label="Kereskedés neve"
      placeholder="CBA..."
      {...other}
    />
  );
}

export default renderMarketInputComponent;
