import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import React, { ReactNode } from "react";
import useRouter from "use-react-router";
import { selectCartCount } from "../../selectors/cart";
import { useGlobalState } from "../../state";
import AppBar, { IProps as AppBarProps } from "../app-bar";
import BottomAppBar from "../bottom-app-bar";

type Props = AppBarProps & {
  children: ReactNode;
  disabledBottomAppbar?: boolean;
  disablePadding?: boolean;
};

const Layout = (props: Props) => {
  const {
    match: { url },
    history,
  } = useRouter();

  const [cart] = useGlobalState("cart");
  const containerStyle = props.disablePadding
    ? {
        padding: 0,
      }
    : {};

  return (
    <Box display={"flex"} flexDirection={"column"} minHeight={"100vh"}>
      <AppBar
        leftSideComponents={props.leftSideComponents}
        rightSideComponents={props.rightSideComponents}
      />

      <Box flexGrow={1} overflow={"scroll"}>
        <Container
          maxWidth="sm"
          style={{ ...containerStyle, height: "100%" }}
        >
          {props.children}
        </Container>
      </Box>

      {!props.disabledBottomAppbar && (
        <BottomAppBar
          url={url}
          onChange={(e, newValue) => {
            history.push(newValue);
          }}
          productsCount={selectCartCount(cart.products)}
        />
      )}
    </Box>
  );
};

export default Layout;
