import {
  Divider,
  FormHelperText,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import { Customer } from "../../generated/graphql";

interface IProps {
  items: [Customer];
  onCustomerClick: (customer: Customer) => void;
}

const FavoriteList: React.FC<IProps> = (props) => {
  return (
    <>
      <Box p={"4px 16px 10px"}>
        <FormHelperText>Kedvencek</FormHelperText>
      </Box>

      <Divider />
      <List>
        {props.items.map((customer) => (
          <>
            <ListItem button onClick={() => props.onCustomerClick(customer)}>
              <div>
                <Typography variant="subtitle1">{customer.name}</Typography>
                <Typography variant="subtitle2">{customer.address}</Typography>
              </div>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
};

export default FavoriteList;
