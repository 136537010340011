import { Grid, Typography } from "@material-ui/core";
import React from "react";
import YesNoDialog from "../yes-no-dialog/intex";

interface IOrderConfirmDialogProps {
  open: boolean;
  onClose: (e: any) => void;
  onConfirm: (e: any) => void;
  customer: string;
  price: string;
}

const OrderConfirmDialog: React.FC<IOrderConfirmDialogProps> = (props) => {
  return (
    <YesNoDialog
      open={props.open}
      title={"Biztosan le adod a rendelést?"}
      yesText={"Igen"}
      noText={"Mégsem"}
      onYesClick={props.onConfirm}
      onNoClick={props.onClose}
      onClose={props.onClose}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"}>Kereskedés:</Typography>
          <Typography variant={"body1"} color={"secondary"}>
            {props.customer}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"}>Ár összesen:</Typography>
          <Typography variant={"subtitle1"} color={"secondary"}>
            {props.price}
          </Typography>
        </Grid>
      </Grid>
    </YesNoDialog>
  );
};

export default OrderConfirmDialog;
