import { IHunaquaInitialState } from "../state";
import { CartActions, cartReducer } from "./cart";

type RootReducer = (
  state: IHunaquaInitialState,
  action: HunaquaActions,
) => IHunaquaInitialState;

export const rootReducer: RootReducer = ({ cart }, action) => ({
  cart: cartReducer(cart, action),
});

export type HunaquaActions = CartActions;
