import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { Product } from "../../generated/graphql";
import { ICartProductUnit } from "../../reducer/cart";
import {
  selectFormattedSumPriceFromUnitsAndProduct,
  selectSumQuantityFromUnits,
  selectUnitByUnits,
} from "../../selectors/product";

export interface IUnitCounterProps {
  units: ICartProductUnit[];
  product: Product;
  hidePrice?: boolean;
}

const UnitCounter: React.FC<IUnitCounterProps> = (props) => {
  const unit = selectUnitByUnits(props.product.units);

  return (
    <Grid container justify="space-around" alignItems="center">
      {props.units.map((cartUnit, i, array) => (
        <React.Fragment key={`unit-counter-${cartUnit.unit.unit}-${i}`}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container alignItems="flex-end" justify="center">
                  <Typography variant="body1" style={{ fontSize: "14px" }}>
                    {cartUnit.quantity}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ marginLeft: "3px", fontSize: "14px" }}
                  >
                    {` * ${cartUnit.unit.multiplier}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ fontSize: "14px" }}>
                  {cartUnit.unit.unit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {i < array.length - 1 && (
            <Grid item>
              <Typography variant="body1" style={{ fontSize: "14px" }}>
                +
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}

      <Grid item>
        <Typography variant="body1">=</Typography>
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Typography variant="body1">
            {selectSumQuantityFromUnits(props.units)} {unit}
          </Typography>
          {!props.hidePrice && (
            <Typography variant="subtitle1">
              {selectFormattedSumPriceFromUnitsAndProduct(
                props.units,
                props.product,
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnitCounter;
