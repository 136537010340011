import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { Customer } from "../../generated/graphql";
import {
  selectCustomerAddress,
  selectCustomerName,
} from "../../selectors/customer";
import MarketMap from "../market-map";

interface IMarketDetailsProps {
  market: Customer;
  onClose: (e: any) => void;
}

const MarketDetails: React.FC<IMarketDetailsProps> = (props) => {
  const [openMap, setOpenMap] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      setOpenMap(false);
      setTimeout(() => setOpenMap(true), 300);
    },
    [props.market],
  );

  return (
    <Box mt={2}>
      <Paper>
        <Box padding={1} pr={3}>
          <Typography variant="h5">
            {selectCustomerName(props.market)}
          </Typography>
          <Typography variant="body2">
            {selectCustomerAddress(props.market)}
          </Typography>
          <Box position={"absolute"} top={0} right={0}>
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={openMap}>
          <MarketMap
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC6OuMunk3s6hgOvriaE0viWKVAy6tOG9I&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
          />
        </Collapse>
      </Paper>
    </Box>
  );
};

export default MarketDetails;
