import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React from "react";

interface IQuantityInputProps {
  value: number;
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange(newQuantity: number): void;
}

const QuantityInput: React.FC<IQuantityInputProps> = (props) => (
  <TextField
    type={"number"}
    value={props.value}
    onChange={(event) => props.onChange(parseInt(event.target.value, 10))}
    inputProps={{
      style: {
        textAlign: "center",
      },
    }}
    label={props.label}
    error={props.error}
    helperText={props.helperText}
    /* eslint-disable-next-line react/jsx-no-duplicate-props */
    InputProps={{
      startAdornment: (
        <InputAdornment position={"start"}>
          <IconButton
            style={{ padding: "10px" }}
            onClick={() => props.onChange(props.value - 1)}
            size={"small"}
          >
            <RemoveIcon />
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position={"end"}>
          <IconButton
            style={{ padding: "10px" }}
            onClick={() => props.onChange(props.value + 1)}
            // disabled={props.error}
            size={"small"}
          >
            <AddIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default QuantityInput;
