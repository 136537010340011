import { Box, Typography } from "@material-ui/core";
import React from "react";
import BoxWithTitle from "../../components/box-with-title";
import ProductList from "../../components/product-list";
import { ProductListItemMode } from "../../components/product-list-item";
import { ICartProduct } from "../../reducer/cart";
import { selectTotalPrice } from "../../selectors/cart";
import { formatCurrency } from "../../utils/currency-formatter";
import { secondaryLinkRenderer } from "./index";

interface ICartItemsSummaryListProps {
  items: ICartProduct[];
  title: string;
  hidePrice?: boolean;
}

const CartItemsSummaryList: React.FC<ICartItemsSummaryListProps> = (props) => {
  return (
    <BoxWithTitle title={props.title} fullWidth={Boolean(props.items.length)}>
      {props.items.length ? (
        <>
          <Box>
            <ProductList
              items={props.items.map((p: ICartProduct) => ({
                ...p,
                mode: ProductListItemMode.CART,
              }))}
              getCartProduct={() => undefined}
              hidePrice={props.hidePrice}
            />
          </Box>
          {!props.hidePrice && (
            <Typography variant="h3" color={"secondary"} align={"center"}>
              {formatCurrency(selectTotalPrice(props.items))}
            </Typography>
          )}
        </>
      ) : (
        <Typography
          component={secondaryLinkRenderer("/items")}
          align={"left"}
          color="textSecondary"
        >
          A kosár üres, kattints ide termék hozzá adásához.
        </Typography>
      )}
    </BoxWithTitle>
  );
};

export default CartItemsSummaryList;
