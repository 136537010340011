import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";

interface ISuccessPasswordChangeDialogProps {
  open: boolean;
  onClose: (e: any) => void;
}

const SuccessPasswordChangeDialog: React.FC<ISuccessPasswordChangeDialogProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen>
      <DialogTitle>
        <Typography variant={"body1"} align="center">
          Sikeres jelszó módosítás!
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Typography variant={"body1"}>
          Mostmár bejelentkezhetsz az új jelszavaddal
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          fullWidth
          onClick={props.onClose}
        >
          Bejelentkezés
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessPasswordChangeDialog;
