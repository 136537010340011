import { Divider, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";
import { RenderSuggestionParams } from "react-autosuggest";
import { Customer } from "../../generated/graphql";
import { selectCustomerName } from "../../selectors/customer";

function renderMarketSuggestion(
  suggestion: Customer,
  { query, isHighlighted }: RenderSuggestionParams,
) {
  const matches = match(selectCustomerName(suggestion), query);
  const parts = parse(selectCustomerName(suggestion), matches);

  return (
    <>
      <MenuItem
        selected={isHighlighted}
        component="div"
        style={{
          whiteSpace: "normal",
        }}
      >
        <div>
          <Typography variant="subtitle1">
            {parts.map((part) => (
              <span
                key={part.text}
                // style={{ fontWeight: part.highlight ? 500 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
          <Typography variant="subtitle2">{suggestion.address}</Typography>
        </div>
      </MenuItem>
      <Divider />
    </>
  );
}

export default renderMarketSuggestion;
